import React, { useEffect, useRef, useState } from 'react';
import { useAsync } from 'react-async';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';

import NewsGridItem from '../news/NewsGridItem.jsx';
import getMotherShipJSON, { sortByOrder } from '../core/MotherShipFeed';
import Loading from '../Loading.jsx';

function HasPosts(properties) {
  const { wait, ready } = properties;
  const rlvReference = useRef();
  const [referenceVisible, setReferenceVisible] = useState(false);

  const postContainer = useRef(null);
  const title = useRef(null);

  const revealReferences = useRef([]);

  const addToReferences = (element, index) => {
    revealReferences.current[index] = element;
  };

  useEffect(() => {
    if (!referenceVisible || !wait) {
      return;
    }
    const totalPanels = revealReferences.current.length - 1;
    let animateBackground;
    let newsListTimeline;
    if (totalPanels > 0) {
      animateBackground = gsap.timeline({
        scrollTrigger: {
          trigger: rlvReference.current,
          start: 'top bottom',
          end: 'botom top',
          markers: false,
          scrub: 1,
        },
      });
      animateBackground.to(document.querySelector('body'), {
        backgroundColor: '#000000',
        duration: 1,
      });

      newsListTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: rlvReference.current,
          pin: true,
          anticipatePin: 1,
          scrub: 1,
          start: 'top top',
          end: `+=${postContainer.current.offsetWidth}`,
          markers: false,
        },
      });

      newsListTimeline.add('start').to(
        revealReferences.current,
        {
          xPercent: -100 * totalPanels,
          ease: 'none',
        },
        'start'
      );
    }
    ready(true);
    // detected rendering
    return () => {
      if (newsListTimeline) {
        newsListTimeline.scrollTrigger?.kill();
        newsListTimeline.pause(0).kill();
      }
      if (animateBackground) {
        animateBackground.scrollTrigger?.kill();
        animateBackground.pause(0).kill();
      }
    };
  }, [referenceVisible, wait, ready]);

  const latestPosts = 'posts';
  const { data: postData, error: postError } = useAsync({
    promiseFn: getMotherShipJSON,
    postType: latestPosts,
  });

  if (postError)
    return (
      <div className="">
        <h1>There has been an Error</h1>
        <p>Post error : {postError.message}</p>
      </div>
    );
  if (postData && postData.length > 0) {
    postData.sort(sortByOrder);
    return (
      <section
        className="[ c-section c-section--news ][ js-section--news ]"
        ref={(element) => {
          rlvReference.current = element;
          setReferenceVisible(!!element);
        }}
      >
        <h2
          className="[ c-section__title ][ c-font__style--title ]"
          ref={title}
        >
          <Link
            to={{
              pathname: '/news',
            }}
          >
            News
          </Link>
        </h2>

        <div
          className="[ c-section__inner ][ b-post__container ]"
          ref={postContainer}
        >
          {postData.slice(0, 5).map((post, index) => (
            <div
              className="[ b-post__item b-post__item--single ][ js-post__item ]"
              key={post.id}
              ref={(element) => addToReferences(element, index)}
            >
              <NewsGridItem
                key={post.id}
                news_featured_image={post.news_featured_image.url}
                title={post.title.rendered}
                slug={post.slug}
                date={post.news_display_date}
                content={post.content.rendered}
                excerpt={post.excerpt.rendered}
                news_excerpt={post.news_excerpt}
              />
            </div>
          ))}
        </div>
        <div className="[ b-divider ]">
          <Link
            to={{
              pathname: '/news',
            }}
            className="[ u-button ]"
          >
            <span className="[ u-button__inner ]">View all News</span>
          </Link>
        </div>
      </section>
    );
  } else {
    return <Loading />;
  }
}

export default HasPosts;
