import React, { Suspense, lazy, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useAsync } from 'react-async';
import { Helmet } from 'react-helmet';

import getMotherShipJSON from './core/MotherShipFeed';
import Header from './_layout/Header.jsx';
import Footer from './_layout/Footer.jsx';
import Newsletter from './_layout/Newsletter.jsx';
import Index from './Index.jsx';
import NotFound from './NotFound.jsx';
import ErrorBoundary from './ErrorBoundary.jsx';
import Loading from './Loading.jsx';

const Article = lazy(() => import('./news/NewsSingle.jsx'));
const News = lazy(() => import('./News.jsx'));
const About = lazy(() => import('./About.jsx'));
const Live = lazy(() => import('./Live.jsx'));
const Music = lazy(() => import('./Music.jsx'));
const Video = lazy(() => import('./Videos.jsx'));
const Photos = lazy(() => import('./Photos.jsx'));
function Router() {
  const brand = 'MØ';
  // eslint-disable-next-line prefer-const
  let [currentLocation, setCurrentLoction] = useState(false);

  if (location.pathname !== '/' && currentLocation !== true) {
    setCurrentLoction((currentLocation = true));
  }

  const artistFeed = 'artists_data';
  const { data: artistData, error: artistError } = useAsync({
    promiseFn: getMotherShipJSON,
    postType: artistFeed,
  });

  if (artistError)
    return (
      <div className="">
        <h1>There has been an error</h1>
        <p>{artistError.message}</p>
      </div>
    );
  if (artistData) {
    return (
      <BrowserRouter>
        <Helmet>
          <title>{artistData?.artist_seo_title}</title>
          <meta content={artistData?.artist_seo_keywords} name="keywords" />
          <meta
            content={artistData?.artist_seo_description}
            property="description"
          />
          <meta content={artistData?.artist_og_image} property="og:image" />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        <Header currentLocation={currentLocation} />
        <main className="[ b-main ]">
          <div className="[ b-main__inner ]">
            <Suspense fallback={<Loading />}>
              <ErrorBoundary>
                <Switch>
                  <Route exact path="/">
                    <Index
                      brand={brand}
                      artistData={artistData}
                      currentLocation={currentLocation}
                      setCurrentLoction={setCurrentLoction}
                    />
                  </Route>
                  <Route exact path="/news">
                    <News brand={brand} />
                  </Route>
                  <Route path="/news/article/:slug">
                    <Article brand={brand} />
                  </Route>
                  <Route exact path="/about">
                    <About
                      brand={brand}
                      artistBio={artistData?.artist_bio_artist}
                      artistImage={artistData?.artist_og_image}
                    />
                  </Route>
                  <Route exact path="/live">
                    <Live brand={brand} />
                  </Route>
                  <Route exact path="/music">
                    <Music brand={brand} />
                  </Route>
                  <Route exact path="/videos">
                    <Video brand={brand} />
                  </Route>
                  <Route exact path="/photos">
                    <Photos brand={brand} />
                  </Route>
                  <Route
                    path="/newsletter"
                    component={() => {
                      window.location.href = artistData?.artist_form_url;
                      return null;
                    }}
                  />
                  <Route>
                    <NotFound brand={brand} />
                  </Route>
                </Switch>
              </ErrorBoundary>
            </Suspense>
          </div>
        </main>
        <Footer
          socials={artistData?.artist_socials}
          newsletter={artistData?.artist_form_url}
          retails={artistData?.artist_retails}
          currentLocation={currentLocation}
        />
        <Newsletter
          brand={brand}
          showNewsletter={artistData?.newsletter_showhide}
          newsletterLeftImageDesktopWidth={
            artistData?.newsletter_image_left?.width
          }
          newsletterLeftImageDesktopHeight={
            artistData?.newsletter_image_left?.height
          }
          newsletterLeftImageDesktop={artistData?.newsletter_image_left?.url}
          newsletterLeftImageMobileWidth={
            artistData?.newsletter_image_top?.width
          }
          newsletterLeftImageMobileHeight={
            artistData?.newsletter_image_top?.height
          }
          newsletterLeftImageMobile={artistData?.newsletter_image_top?.url}
          newsletterBehaviour={artistData?.newsletter_behaviour}
          newsletterCopy={artistData?.newsletter_text}
          newsletterButtonCopy={artistData?.newsletter_cta_text}
          newsletterButtonLink={artistData?.artist_form_url}
          newsletterDelay={artistData?.newsletter_delay}
          newsletterExpiry={artistData?.cookie_expiry}
          newsletter_image_left={artistData?.newsletter_image_left}
          artist_logo_newsletter={artistData?.artist_logo_newsletter}
        />
      </BrowserRouter>
    );
  }
  return null;
}

Router.propTypes = {};

export default Router;
