import React, { useEffect, useRef, useState } from 'react';
import { useAsync } from 'react-async';
import { gsap } from 'gsap';

import Loading from '../Loading.jsx';
import HeroCarouselItem from '../hero/HeroCarouselItem.jsx';
import getMotherShipJSON, { sortByOrder } from '../core/MotherShipFeed';

const detectMob = () => {
  return window.innerWidth <= 768;
};

function HasCarousel(properties) {
  const { ready } = properties;
  const rlvReference = useRef();
  const [referenceVisible, setReferenceVisible] = useState(false);
  const carouselReference = useRef();
  const panels = useRef([]);

  const createPanelsReferences = (panel, index) => {
    panels.current[index] = panel;
  };

  useEffect(() => {
    if (!referenceVisible) {
      return;
    }

    const totalPanels = panels.current.length - 1;
    let carouselTimeline;
    let headerTimeline;
    const carouselSpeed = detectMob ? totalPanels * 0.1 : totalPanels;

    if (totalPanels > 0) {
      carouselTimeline = gsap.to(panels.current, {
        xPercent: -100 * totalPanels,
        ease: 'none', // <-- IMPORTANT!
        scrollTrigger: {
          trigger: carouselReference.current,
          pin: true,
          anticipatePin: 1,
          scrub: true,
          end: () =>
            `+=${carouselReference?.current?.offsetWidth / carouselSpeed}`,
        },
      });
      for (const panel of panels.current) {
        const content = panel.querySelector('.js-carousel__content');
        const background = panel.querySelector('.b-carousel__item');
        const gsaptimeline = gsap.timeline({
          scrollTrigger: {
            trigger: panel,
            scrub: true,
            containerAnimation: carouselTimeline,
            start: '35% center',
            end: '70% center',
            markers: false,
          },
        });
        if (content) {
          gsaptimeline.to(background, {
            backgroundPosition: '+=50vw',
            ease: 'none',
          });
        }
      }
    }
    ready(true);
    return () => {
      if (headerTimeline) {
        headerTimeline.scrollTrigger?.kill();
        headerTimeline.pause(0).kill();
      }
      if (carouselTimeline) {
        carouselTimeline.scrollTrigger?.kill();
        carouselTimeline.pause(0).kill();
      }
    };
  }, [referenceVisible, ready]);

  const carouselParent = useRef(null);
  const carouselFeed = 'carousels';
  const { data: carouselData, error: carouselError } = useAsync({
    promiseFn: getMotherShipJSON,
    postType: carouselFeed,
    //overRideID,
  });
  if (!carouselParent) {
    return null;
  }

  if (carouselError) {
    return (
      <div className="">
        <h1>There has been an postError</h1>
        <p>Carousel error : {carouselError.message}</p>
      </div>
    );
  }

  if (carouselData && carouselData.length > 0) {
    carouselData.sort(sortByOrder);
    return (
      <div
        className="[ b-carousel__container ]"
        ref={(element) => {
          rlvReference.current = element;
          setReferenceVisible(!!element);
        }}
      >
        <div className="[ b-carousel ][ js-carousel ]" ref={carouselReference}>
          {carouselData.map((carouselItem, index) => (
            <div
              className={
                carouselItem.title.rendered !== undefined &&
                carouselItem.title.rendered !== null &&
                carouselItem.title.rendered !== '' &&
                carouselItem.hide_title !== true
                  ? `[ b-carousel__item__container--wide ][ js-carousel__item js-carousel__item--content ]`
                  : `[ b-carousel__item__container--default js-carousel__item ]`
              }
              key={carouselItem.id}
              ref={(event) => createPanelsReferences(event, index)}
            >
              <HeroCarouselItem
                id={carouselItem.id}
                index={index}
                order={carouselItem?.order}
                slug={carouselItem?.slug}
                title={carouselItem?.title}
                hide_title={carouselItem?.hide_title}
                subtitle={carouselItem?.subtitle}
                hide_subtitle={carouselItem?.hide_subtitle}
                content={carouselItem?.content}
                button={carouselItem?.button}
                buttons={carouselItem?.buttons}
                button_type={carouselItem?.button_type}
                button_link={carouselItem?.button_link}
                button_target={carouselItem?.button_target}
                button_text={carouselItem?.button_text}
                slide_background_type={carouselItem?.slide_background_type}
                background_video_horizontal={
                  carouselItem?.background_video_horizontal
                }
                background_video_vertical={
                  carouselItem?.background_video_vertical
                }
                background_image={carouselItem?.background_image}
                background_image_mobile={carouselItem?.background_image_mobile}
                whole_slide_cta={carouselItem?.whole_slide_cta}
                whole_slide_cta_url={carouselItem?.whole_slide_cta_url}
                whole_slide_cta_text={carouselItem?.whole_slide_cta_text}
              />
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
}

export default HasCarousel;
