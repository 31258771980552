import React, { useEffect, useRef, useState } from 'react';
import { useAsync } from 'react-async';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';

import VideoItem from '../videos/VideoItem.jsx';
import getMotherShipJSON, { sortByOrder } from '../core/MotherShipFeed';

function HasVideos(properties) {
  const { wait } = properties;
  const rlvReference = useRef();
  const [referenceVisible, setReferenceVisible] = useState(false);
  const videoContainer = useRef(null);
  const title = useRef(null);
  const panels = useRef([]);

  const createVideoReferences = (panel, index) => {
    panels.current[index] = panel;
  };

  useEffect(() => {
    if (!referenceVisible || !wait) {
      return;
    }
    const totalPanels = panels.current.length - 1;
    let animateVideosTimeline;
    let animateBackground;
    if (totalPanels > 0) {
      animateBackground = gsap.timeline({
        scrollTrigger: {
          trigger: rlvReference.current,
          start: 'top bottom',
          end: 'top bottom',
          markers: false,
          scrub: 1,
        },
      });
      animateBackground.to(document.querySelector('body'), {
        backgroundColor: '#902536',
        duration: 1,
      });
      for (const panel of panels.current) {
        animateVideosTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: panel,
            start: 'top center',
            end: 'bottom bottom',
            markers: false,
            scrub: 1,
          },
        });
        animateVideosTimeline.fromTo(
          panel,
          { opacity: 0, x: '-10%' },
          { opacity: 1, x: '0%' }
        );
      }
      gsap.timeline({
        scrollTrigger: {
          trigger: rlvReference.current,
          start: 'top top',
          end: 'bottom 70%',
          scrub: 1,
          pin: title.current,
          markers: false,
          pinSpacing: false,
        },
      });
    }
    return () => {
      if (animateVideosTimeline) {
        animateVideosTimeline.scrollTrigger?.kill();
        animateVideosTimeline.pause(0).kill();
      }
      if (animateBackground) {
        animateBackground.scrollTrigger?.kill();
        animateBackground.pause(0).kill();
      }
    };
  }, [referenceVisible, wait]);

  const videosFeed = 'videos';
  const { data: videoData, error: dataError } = useAsync({
    promiseFn: getMotherShipJSON,
    postType: videosFeed,
  });

  if (dataError)
    return (
      <div className="">
        <h1>There has been an Error</h1>
        <p>Post error : {dataError.message}</p>
      </div>
    );

  if (videoData && videoData.length > 0) {
    videoData.sort(sortByOrder);
    const filteredVideos = videoData.slice(0, 5);
    return (
      <section
        className="[ c-section c-section--videos ][ js-section--videos ]"
        ref={(element) => {
          rlvReference.current = element;
          setReferenceVisible(!!element);
        }}
      >
        <div className="[ u-wrapper ]">
          <h2
            className="[ c-section__title ][ c-font__style--title ]"
            ref={title}
          >
            <Link
              to={{
                pathname: '/videos',
              }}
            >
              Videos
            </Link>
          </h2>
          <div
            className="[ c-section__inner ][ b-video__container ]"
            ref={videoContainer}
          >
            {filteredVideos.map((video, index) => (
              <div
                className="[ b-video__item ]"
                key={video.id}
                data-order={video.order}
                ref={(event) => createVideoReferences(event, index)}
              >
                <VideoItem video={video} />
              </div>
            ))}
          </div>

          <div className="[ b-divider ]">
            <Link
              to={{
                pathname: '/videos',
              }}
              className="[ u-button ]"
            >
              <span className="[ u-button__inner ]">View all Videos</span>
            </Link>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <div className="[ b-404 ]">
        <img
          src={`/assets/images/logo_lightbg.png`}
          width={445}
          height={592}
          title="MØ"
        />
        <h1 className="[ b-404__title ]">This page is not found</h1>
      </div>
    );
  }
}

export default HasVideos;
