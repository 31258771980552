/* eslint-disable compat/compat */
import memoize from 'lodash/memoize';

const contactMothership = async (
  { postType, overRideID = 196 /* Default Band Mothership ID */ },
  { signal },
) => {
  const response = await fetch(
    `https://cdn-p.smehost.net/sites/5cfaf3980b294dd89a79248f35560b2f/mothership/artist/${overRideID}/${postType}.json`,
    {
      signal,
    },
  );
  if (!response.ok) throw new Error(response.status);
  return response.json();
};

export const getShopifyJSON = async ({ storeID }, { signal }) => {
  const response = await fetch(
    `https://${storeID}/collections/rag-n-bone-man-cd-vinyl-bundle/products.json?limit=3`,
    { signal },
  );

  if (!response.ok) throw new Error(response.status);
  return response.json();
};

export const removeHtml = (string) => {
  return string.replace(/<[^>]+>/g, '');
};

export function sortByOrder(a, b) {
  return parseInt(a.order) > parseInt(b.order)
    ? 1
    : parseInt(b.order) > parseInt(a.order)
      ? -1
      : 0;
}

const getMotherShipJSON = memoize(contactMothership, (parameters) => {
  return JSON.stringify(parameters);
});

export default getMotherShipJSON;
