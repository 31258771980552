import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
const initialState = {
  burgerMenuActive: false,
  showLayoutSidebar: false,
};

const reducer = (state, action) => {
  const copy = { ...state };
  switch (action.type) {
    case 'switch':
      copy[action.payload] = !state[action.payload];
      return copy;
    default:
      return state;
  }
};

const UIContext = createContext(initialState);

export function ProvideUI({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <UIContext.Provider value={[state, dispatch]}>
      {children}
    </UIContext.Provider>
  );
}

ProvideUI.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default ProvideUI;
