import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

import useWindowDimensions from '../core/useWindowDimensions';

function Header(properties) {
  const {
    brand,
    showNewsletter,
    newsletterLeftImageDesktopWidth,
    newsletterLeftImageDesktopHeight,
    newsletterLeftImageDesktop,
    newsletterLeftImageMobileWidth,
    newsletterLeftImageMobileHeight,
    newsletterLeftImageMobile,
    newsletterBehaviour,
    newsletterCopy,
    newsletterButtonCopy,
    newsletterButtonLink,
    newsletterDelay,
    newsletterExpiry,
    artist_logo_newsletter,
  } = properties;

  const [showModal, setShowModal] = useState(false);

  const [cookies, setCookie] = useCookies();

  const { width } = useWindowDimensions();
  const dynamicImage = {};
  dynamicImage.url =
    width > 768 ? newsletterLeftImageDesktop : newsletterLeftImageMobile;
  dynamicImage.width =
    width > 768
      ? newsletterLeftImageDesktopWidth
      : newsletterLeftImageMobileWidth;
  dynamicImage.height =
    width > 768
      ? newsletterLeftImageDesktopHeight
      : newsletterLeftImageMobileHeight;

  const cookieDate = new Date();
  switch (newsletterExpiry?.units) {
    case 'days': {
      const days = Number(newsletterExpiry.amount);
      cookieDate.setDate(cookieDate.getDate() + days);
      break;
    }
    case 'weeks': {
      const weeks = Number(newsletterExpiry.amount) * 7;
      cookieDate.setDate(cookieDate.getDate() + weeks);
      break;
    }
    case 'months': {
      const months = Number(newsletterExpiry.amount);
      cookieDate.setMonth(cookieDate.getMonth() + months);
      break;
    }
    case 'years': {
      const years = Number(newsletterExpiry.amount);
      cookieDate.setFullYear(cookieDate.getFullYear() + years);
      break;
    }
    default: {
      cookieDate.setDate(cookieDate.getMonth() + 3);
      break;
    }
  }

  function hideNewsletter() {
    setCookie('sme_newsletter_prompt', 'hidden', [
      {
        maxAge: cookieDate.toUTCString(),
      },
    ]);
    setShowModal(!showModal);
  }

  switch (newsletterBehaviour) {
    case 'timed': {
      const delay = Number(newsletterDelay) * 1000;
      if (showModal === false) {
        setTimeout(() => {
          setShowModal(!showModal);
        }, delay);
      }
      break;
    }
    case 'clicked': {
      //ToDo:make it work on click if that's required
      break;
    }
  }

  return showNewsletter &&
    (cookies.sme_newsletter_prompt === null ||
      cookies.sme_newsletter_prompt === undefined ||
      cookies.sme_newsletter_prompt === '') &&
    cookies.sme_newsletter_prompt !== 'hidden' ? (
    <div>
      <div
        className={`[ b-modal ]${showModal ? ' is-active' : ''}`}
        id="newsletterModal"
      >
        <button className="[ b-modal__close ]" onClick={() => hideNewsletter()}>
          <p className="[ u-hidden-visually ]">Close</p>
        </button>

        <div className="[ b-modal__content ]">
          <div className="[ c-layout__grid c-layout__grid--column-2 c-layout__grid--column-1@mobile ]">
            <div className="[ b-modal__thumb ]">
              <div className="[ b-modal__thumb__inner ]">
                <img
                  src={dynamicImage.url}
                  alt={`Sign up to the ${brand} newsletter`}
                  width={dynamicImage.width}
                  height={dynamicImage.height}
                />
              </div>
            </div>
            <div className="[ b-modal__copy ]">
              <img
                alt={brand}
                src={artist_logo_newsletter ? artist_logo_newsletter : ''}
              />
              <div
                className="[ b-modal__copy__text ]"
                dangerouslySetInnerHTML={{
                  __html: newsletterCopy,
                }}
              />
              <div className="[ b-modal__actions ]">
                <a
                  href={newsletterButtonLink}
                  className="[ u-button ][ b-modal__action ]"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => hideNewsletter()}
                >
                  {newsletterButtonCopy}
                </a>
                <button
                  className="[ u-button ][ b-modal__action b-modal__action--negative ]"
                  onClick={() => hideNewsletter()}
                >
                  No thanks
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`[ b-modal__overlay ]${showModal ? ' is-active' : ''}`}
      ></div>
    </div>
  ) : null;
}

Header.propTypes = {};

export default Header;
