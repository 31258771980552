import React, { useEffect, useRef, useState } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import OnImagesLoaded from 'react-on-images-loaded';
import { Helmet } from 'react-helmet';

import HasCarousel from './_snippets/HasCarousel.jsx';
import HasPosts from './_snippets/HasPosts.jsx';
import HasVideos from './_snippets/HasVideos.jsx';
import HasNewsletter from './_snippets/HasNewsletter.jsx';

function Index(properties) {
  const { artistData } = properties;
  const pageLoader = useRef();
  const [carouselReady, setCarouselReady] = useState(false);
  const [postsReady, setPostsReady] = useState(false);
  const [newsletterReady, setNewsletterReady] = useState(false);
  useEffect(() => {
    //This is all triggered when the page "loads"
    document.body.classList.add('home', 'is-loaded');
    document.querySelector('.js-brand').removeAttribute('style');
    document.querySelector('.js-brand img').removeAttribute('style');

    return () => {
      //This is all triggered when the page "unloads"
      document.body.classList.remove('home', 'is-loaded');
      const hasBrand = document.querySelector('.js-brand');
      const brandImage = document.querySelector('.js-brand img');
      if (hasBrand) {
        hasBrand.removeAttribute('style');
      }
      if (brandImage) {
        brandImage.removeAttribute('style');
      }
    };
  }, [pageLoader]);

  return (
    <OnImagesLoaded
      onLoaded={() => {
        ScrollTrigger.refresh(true);
      }}
      ref={pageLoader}
    >
      <Helmet>
        <title>{artistData?.artist_seo_title}</title>
        <meta content={artistData?.artist_seo_keywords} name="keywords" />
        <meta
          content={artistData?.artist_seo_description}
          property="description"
        />
        <meta content={artistData?.artist_og_image} property="og:image" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="[ b-home ]">
        <HasCarousel ready={setCarouselReady} />

        <HasPosts wait={carouselReady} ready={setPostsReady} />

        <HasNewsletter
          artistData={artistData}
          wait={postsReady}
          ready={setNewsletterReady}
        />

        <HasVideos wait={newsletterReady} />
      </div>
    </OnImagesLoaded>
  );
}
export default Index;
