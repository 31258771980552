import React, { useRef, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

const getDimensions = (ele) => {
  const { height } = ele.getBoundingClientRect();
  const { offsetTop } = ele;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};
function Header(properties) {
  const [navigation, setNavigation] = useState(false);

  const headerReference = useRef(null);
  const burgerReference = useRef(null);

  const { currentLocation } = properties;

  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(headerReference.current);
      if (window.scrollY > headerHeight * 2) {
        headerReference.current.classList.add('is-active');
      } else {
        if (headerReference.current.classList.contains('is-active')) {
          headerReference.current.classList.remove('is-active');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  return (
    <header
      className={`[ b-header ]${navigation ? ' is-open' : ''}${
        currentLocation ? ' is-pulldown' : ''
      }`}
      ref={headerReference}
    >
      <div className="[ b-header__inner ] [ u-wrapper ]">
        <ul className="[ b-nav ]">
          <li className="[ b-nav__item ]">
            <a
              href="https://mo.merchtable.com"
              target="_blank"
              className="[ b-nav__link ]"
              rel="noopener noreferrer"
              onClick={() => {
                setNavigation(false);
              }}
            >
              Store
              <span className="[ u-hidden-visually ]">
                (Opens in new window)
              </span>
            </a>
          </li>
          <li className="[ b-nav__item ]">
            <NavLink
              to="/videos"
              className="[ b-nav__link ]"
              activeClassName="is-active"
              onClick={() => {
                setNavigation(false);
              }}
            >
              Videos
            </NavLink>
          </li>
          <li className="[ b-nav__item ]">
            <NavLink
              to="/news"
              className="[ b-nav__link ]"
              activeClassName="is-active"
              onClick={() => {
                setNavigation(false);
              }}
            >
              News
            </NavLink>
          </li>
          <li className="[ b-nav__item b-nav__item--brand ][ js-brand ]">
            <div className="[ b-brand ]">
              <Link to="/">
                <img
                  src={`/assets/images/logo.png`}
                  width={445}
                  height={592}
                  title="MØ"
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                />
              </Link>
            </div>
          </li>
          <li className="[ b-nav__item ]">
            <NavLink
              to="/live"
              className="[ b-nav__link ]"
              activeClassName="is-active"
              onClick={() => {
                setNavigation(false);
              }}
            >
              Live
            </NavLink>
          </li>
          <li className="[ b-nav__item ]">
            <NavLink
              to="/music"
              className="[ b-nav__link ]"
              activeClassName="is-active"
              onClick={() => {
                setNavigation(false);
              }}
            >
              Music
            </NavLink>
          </li>
          <li className="[ b-nav__item ]">
            <NavLink
              to="/photos"
              className="[ b-nav__link ]"
              activeClassName="is-active"
              onClick={() => {
                setNavigation(false);
              }}
            >
              Photos
            </NavLink>
          </li>
          <li className="[ b-nav__item b-nav__item--mobile ]">
            <NavLink
              to="/about"
              className="[ b-nav__link ]"
              activeClassName="is-active"
            >
              About
            </NavLink>
          </li>
          <li className="[ b-nav__item b-nav__item--mobile ]">
            <NavLink
              to="/newsletter"
              className="[ b-nav__link ]"
              target="_blank"
              rel="noopener noreferrer"
              activeClassName="is-active"
            >
              Newsletter
            </NavLink>
          </li>
        </ul>
        <div className="[ b-brandMobile__container ]">
          <div className="[ b-brandMobile ]">
            <Link to="/">
              <img
                src={`/assets/images/logo.png`}
                width={445}
                height={592}
                title="MØ"
              />
            </Link>
          </div>
          <button
            title="Open mobile navigation"
            className={`u-burger${navigation ? ' is-active' : ''}`}
            ref={burgerReference}
            onClick={() => {
              setNavigation(!navigation);
            }}
          >
            <span className="u-burger__icon"></span>
            <span className="u-hidden-visually">Open Mobile navigation</span>
          </button>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {};

export default Header;
