import React from 'react';

import useWindowDimensions from '../core/useWindowDimensions';

function HeroCarouselItem(properties) {
  const {
    index,
    title,
    hide_title,
    subtitle,
    hide_subtitle,
    content,
    button,
    buttons,
    button_type,
    button_link,
    button_target,
    button_text,
    slide_background_type,
    background_video_horizontal,
    background_video_vertical,
    background_image,
    background_image_mobile,
    whole_slide_cta,
    whole_slide_cta_url,
    whole_slide_cta_text,
    order,
  } = properties;

  function GetTitle(properties) {
    const { title, hide_title, index } = properties;

    const CustomTag = index == 0 ? 'h1' : 'h2';
    return title !== undefined &&
      title !== null &&
      title !== '' &&
      hide_title !== true ? (
      <CustomTag
        className="[ b-carousel__title ]"
        dangerouslySetInnerHTML={{
          __html: `<span class="[ b-carousel__title__inner ]">${title}</span>`,
        }}
      />
    ) : null;
  }

  function GetSubTitle(properties) {
    const { subtitle, hide_subtitle, index } = properties;
    const CustomTag = index == 0 ? 'h2' : 'h3';
    return subtitle !== undefined &&
      subtitle !== null &&
      subtitle !== '' &&
      hide_subtitle !== true ? (
      <CustomTag className="[ b-carousel__subtitle ][ c-font__family--omnes c-font__size--h2 c-font__weight--semibold ]">
        {subtitle}
      </CustomTag>
    ) : null;
  }

  function GetContent(properties) {
    const { content } = properties;
    return content !== undefined && content !== null && content !== '' ? (
      <div
        className="[ b-carousel__copy ]"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    ) : null;
  }

  function GetOpenInNewWindow(properties) {
    const { type } = properties;
    return type === 'external' ? (
      <span className="[ u-hidden-visually ]">(Opens in new window)</span>
    ) : null;
  }

  function GetButtons(properties) {
    const { button, buttons } = properties;

    return buttons !== undefined && buttons !== null && button ? (
      <div className="[ b-carousel__actions ]">
        {buttons.map((eachButton, index) => (
          <div className="[ b-link__item ]" key={index}>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              href={eachButton.button_link}
              className="[ u-button ]"
              target={
                eachButton.button_type === 'external' ? '_blank' : '_self'
              }
              rel={
                eachButton.button_type === 'external'
                  ? 'noopener noreferrer'
                  : ''
              }
            >
              <span className="[ u-button__inner ]">
                {eachButton.button_text}
              </span>
              <GetOpenInNewWindow type={eachButton.button_type} />
            </a>
          </div>
        ))}
      </div>
    ) : null;
  }

  function WholeSlideCTA(properties) {
    const { whole_slide_cta, whole_slide_cta_url, whole_slide_cta_text } =
      properties;

    return whole_slide_cta ? (
      <a
        href={whole_slide_cta_url}
        className="[ u-fullClick ]"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>{whole_slide_cta_text}</span>
      </a>
    ) : null;
  }

  function HasSingleButton(properties) {
    const { button_type, button_link, button_text } = properties;
    return button_text !== undefined && button_text !== null && button_text ? (
      <div className="[ b-link__item ]">
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          href={button_link}
          className="[ b-link__item__action ][ b-carousel__link ]"
          target={button_type === 'external' ? '_blank' : '_self'}
          rel={button_type === 'external' ? 'noopener noreferrer' : ''}
        >
          <span className="[ b-link__item__span ]">{button_text}</span>
          <GetOpenInNewWindow type={button_type} />
        </a>
      </div>
    ) : null;
  }

  const { width } = useWindowDimensions();
  let initMedia = '';

  if (width > 768) {
    initMedia = background_video_horizontal || background_image;
  } else {
    initMedia = background_video_vertical || background_image_mobile;
  }

  switch (slide_background_type) {
    case 'background-video-vimeo':
      return (
        <div
          className={`[ b-carousel__item ][ c-ratio c-ratio--16:9 c-ratio--9:16@mobile c-ratio--9:16@tabletPortrait order-${order} ]`}
        >
          <iframe
            src={`https://player.vimeo.com/video/${initMedia}?autoplay=1&amp;loop=1&amp;title=0&amp;byline=0&amp;background=1`}
            allow="autoplay;fullscreen"
            allowFullScreen=""
            frameBorder="0"
          ></iframe>
          {title.rendered !== undefined &&
          title.rendered !== null &&
          title.rendered !== '' &&
          hide_title !== true ? (
            <div className="[ b-carousel__content ][ js-carousel__content ]">
              <GetTitle
                title={title.rendered}
                hide_title={hide_title}
                index={index}
              />
              <GetSubTitle
                subtitle={subtitle}
                hide_subtitle={hide_subtitle}
                index={index}
              />

              <GetContent content={content.rendered} />
              <GetButtons button={button} buttons={buttons} />

              <HasSingleButton
                button={button}
                button_type={button_type}
                button_link={button_link}
                button_target={button_target}
                button_text={button_text}
              />
            </div>
          ) : null}
        </div>
      );
    case 'background-image':
      return (
        <div
          className="[ b-carousel__item ][ c-ratio c-ratio--16:9 c-ratio--9:16@mobile c-ratio--9:16@tabletPortrait ]"
          style={{
            backgroundImage: `url(${initMedia})`,
          }}
          data-desktop={background_image}
          data-mobile={background_image_mobile}
        >
          {title.rendered !== undefined &&
          title.rendered !== null &&
          title.rendered !== '' &&
          hide_title !== true ? (
            <div className="[ b-carousel__content ][ js-carousel__content ]">
              <GetTitle
                title={title.rendered}
                hide_title={hide_title}
                index={index}
              />
              <GetSubTitle
                subtitle={subtitle}
                hide_subtitle={hide_subtitle}
                index={index}
              />

              <GetContent content={content.rendered} />
              <GetButtons button={button} buttons={buttons} />

              <HasSingleButton
                button={button}
                button_type={button_type}
                button_link={button_link}
                button_target={button_target}
                button_text={button_text}
              />
            </div>
          ) : null}
          <WholeSlideCTA
            whole_slide_cta={whole_slide_cta}
            whole_slide_cta_url={whole_slide_cta_url}
            whole_slide_cta_text={whole_slide_cta_text}
          />
        </div>
      );
    default:
      return null;
  }
}

export default HeroCarouselItem;
