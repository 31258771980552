import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

function NotFound(properties) {
  const { brand } = properties;

  useEffect(() => {
    document.body.classList.add('no-template', 'is-loaded');
    document.querySelector('.js-brand').removeAttribute('style');
    document.querySelector('.js-brand img').removeAttribute('style');
    return () => {
      document.body.classList.remove('no-template', 'is-loaded');
      document.querySelector('.js-brand').removeAttribute('style');
      document.querySelector('.js-brand img').removeAttribute('style');
    };
  }, []);

  return (
    <div className="[ b-404 ]">
      <Helmet>
        <title>Not Found | {brand} | The Official Website</title>
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <img
        src={`/assets/images/logo_lightbg.png`}
        width={445}
        height={592}
        title="MØ"
      />
      <h1 className="[ b-404__title ]">This page is not found</h1>
    </div>
  );
}

NotFound.propTypes = {
  brand: PropTypes.string,
};

export default NotFound;
