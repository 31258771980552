import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import GLightbox from 'glightbox/src/js/glightbox';
import SVG from 'react-inlinesvg';

function Videos(properties) {
  useEffect(() => {});

  const { video } = properties;

  return (
    <div className="">
      <button
        data-videoid={video.youtube}
        className="[ b-video__button ]"
        title="Play Video"
        onClick={() => {
          const myGallery = GLightbox({
            elements: [
              {
                content: `<div class="c-ratio c-ratio--16:9"><iframe width="420" height="315" src="https://www.youtube.com/embed/${video.youtube}?autoplay=1&rel=0"></iframe></div>`,
              },
            ],
            autoplayVideos: true,
          });
          myGallery.open();
        }}
      >
        <SVG
          src={`/assets/images/play.svg`}
          width={88}
          height={88}
          title="Play Video"
        />
      </button>
      <div className="[ b-video__item__inner ]">
        <img
          width="1280"
          height="720"
          src={
            video.thumb_image ||
            `https://img.youtube.com/vi/${video.youtube}/maxresdefault.jpg`
          }
          className="[ b-video__thumb ]"
        />
      </div>
    </div>
  );
}

Videos.propTypes = {
  brand: PropTypes.string,
};

export default Videos;
