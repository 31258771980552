import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';

function Footer(properties) {
  const { socials, currentLocation, newsletter } = properties;

  return (
    <footer className={`[ b-footer ]${currentLocation ? ' is-pullup' : ''}`}>
      <div className="[ u-wrapper ]">
        <div className="[ b-footer__inner ][ u-wrapper ][ c-font__size--small c-font__weight--light ]">
          <div className="[ b-footer__copy ]">
            <p className="[ b-footer__copy__content ]">
              &copy; {new Date().getFullYear()} Sony Music Entertainment UK Ltd.
            </p>
            <ul className="[ b-footer__nav ]">
              <li className="[ b-footer__copy__content ]">
                <a
                  href="https://mo.merchtable.com"
                  target="_blank"
                  className="[ b-footer__nav__link ]"
                  rel="noopener noreferrer"
                >
                  Store
                  <span className="[ u-hidden-visually ]">
                    (Opens in new window)
                  </span>
                </a>
              </li>
              <li className="[ b-footer__copy__content ]">
                <NavLink
                  to="/videos"
                  className="[ b-footer__nav__link ]"
                  activeClassName="is-active"
                >
                  Videos
                </NavLink>
              </li>
              <li className="[ b-footer__copy__content ]">
                <NavLink
                  to="/news"
                  className="[ b-footer__nav__link ]"
                  activeClassName="is-active"
                >
                  News
                </NavLink>
              </li>
              <li className="[ b-footer__copy__content ]">
                <NavLink
                  to="/about"
                  className="[ b-footer__nav__link ]"
                  activeClassName="is-active"
                >
                  About
                </NavLink>
              </li>
              <li className="[ b-footer__copy__content ]">
                <NavLink
                  to="/newsletter"
                  className="[ b-footer__nav__link ]"
                  target="_blank"
                  rel="noopener noreferrer"
                  activeClassName="is-active"
                >
                  Newsletter
                </NavLink>
              </li>

              <li className="[ b-footer__copy__content ]">
                <NavLink
                  to="/live"
                  className="[ b-footer__nav__link ]"
                  activeClassName="is-active"
                >
                  Live
                </NavLink>
              </li>
              <li className="[ b-footer__copy__content ]">
                <NavLink
                  to="/music"
                  className="[ b-footer__nav__link ]"
                  activeClassName="is-active"
                >
                  Music
                </NavLink>
              </li>
              <li className="[ b-footer__copy__content ]">
                <NavLink
                  to="/photos"
                  className="[ b-footer__nav__link ]"
                  activeClassName="is-active"
                >
                  Photos
                </NavLink>
              </li>
              <li className="[ b-footer__copy__content ]">
                <a
                  href="https://www.sonymusic.co.uk/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy &amp; Cookie Policy
                  <span className="[ u-hidden-visually ]">
                    (Opens in a new window)
                  </span>
                </a>
              </li>
              <li className="[ b-footer__copy__content ]">
                <a
                  href="https://www.sonymusic.co.uk/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms &amp; Conditions
                  <span className="[ u-hidden-visually ]">
                    (Opens in a new window)
                  </span>
                </a>
              </li>
              <li className="[ b-footer__copy__content ]">
                <a
                  href="https://www.sonymusic.co.uk/ai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AI Usage
                  <span className="[ u-hidden-visually ]">
                    (Opens in a new window)
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <ul className="[ b-social ]">
            {socials.map((socialButton, index) => (
              <li className="[ b-social__item ]" key={index}>
                <a
                  href={socialButton.artist_social_url}
                  target="_blank"
                  className="[ b-social__link ]"
                  rel="noopener noreferrer"
                  aria-label={socialButton.artist_social_type}
                >
                  {(function () {
                    switch (socialButton.artist_social_type) {
                      case 'facebook':
                        return (
                          <SVG
                            src={`/assets/images/icon-facebook.svg`}
                            width={9}
                            height={16}
                            title="Facebook"
                          />
                        );
                      case 'instagram':
                        return (
                          <SVG
                            src={`/assets/images/icon-instagram.svg`}
                            width={17}
                            height={16}
                            title="Instagram"
                          />
                        );
                      case 'twitter':
                        return (
                          <SVG
                            src={`/assets/images/icon-twitter.svg`}
                            width={17}
                            height={13}
                            title="Twitter"
                          />
                        );
                      case 'youtube':
                        return (
                          <SVG
                            src={`/assets/images/icon-youtube.svg`}
                            width={17}
                            height={12}
                            title="Twitter"
                          />
                        );
                      case 'tiktok':
                        return (
                          <SVG
                            src={`/assets/images/icon-tiktok.svg`}
                            width={17}
                            height={12}
                            title="Tiktok"
                          />
                        );
                    }
                  })()}
                  <span className="[ u-hidden-visually ]">
                    (Opens in a new window)
                  </span>
                </a>
              </li>
            ))}
            <li className="[ b-social__item ]">
              <a
                href={newsletter}
                target="_blank"
                className="[ b-social__link ]"
                rel="noopener noreferrer"
                aria-label="Sign up to our newsletter"
              >
                <SVG
                  src={`/assets/images/icon-email.svg`}
                  width={17}
                  height={13}
                  aria-label="Email"
                />
                <span className="[ u-hidden-visually ]">
                  (Opens in a new window)
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  socials: PropTypes.array,
};

export default Footer;
