import React from 'react';
import PropTypes from 'prop-types';
class ErrorBoundary extends React.Component {
  constructor(properties) {
    super(properties);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    /*    console.log(error);
    console.log(errorInfo); */
    // You can also log error messages to an error reporting service here
  }

  render() {
    const { state, props } = this;
    if (state.errorInfo) {
      // Error path
      return (
        <div className="[ b-404 ]">
          <img
            src={`/assets/images/logo_lightbg.png`}
            width={445}
            height={592}
            title="MØ"
          />
          <h1 className="[ b-404__title ]">There has been an error</h1>
        </div>
      );
    }
    // Normally, just render children
    return props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default ErrorBoundary;
