import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

class NewsGridItem extends Component {
  constructor(properties) {
    super(properties);
    this.state = {};
  }
  render() {
    const { id, title, news_featured_image, slug, date } = this.props;
    return (
      <div
        className=""
        key={id}
        itemScope=""
        itemType="https://schema.org/NewsArticle"
      >
        <div className="[ b-post__thumb ]">
          <img src={news_featured_image} alt={title} itemProp="thumbnail" />
        </div>
        <div
          className="[ b-post__content ]"
          itemProp="publisher"
          itemScope
          itemType="https://schema.org/Organization"
        >
          <meta itemProp="name" content="Sony" />
          <h3
            className="[ b-post__title ][ c-font__colour--light c-font__size--xxlarge c-font__weight--black c-font__transform--upper ]"
            itemProp="headline"
            content={title}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <p className="[ b-post__date ][ c-font__colour--light c-font__size--xlarge c-font__weight--thin ]">
            {DateTime.fromFormat(date, 'dd/LL/yyyy').toFormat('yyyy.LL.dd')}
          </p>
        </div>
        <h3
          className="[ b-post__title b-post__title--outline ][ c-font__colour--light c-font__size--xxlarge c-font__weight--black c-font__transform--upper ]"
          itemProp="headline"
          content={title}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
          aria-hidden="true"
        />
        <Link
          className="[ u-fullClick ]"
          to={{
            pathname: `/news/article/${slug}`,
          }}
        >
          <span
            className="[  ]"
            dangerouslySetInnerHTML={{
              __html: `Read the full artucle ${title}`,
            }}
          />
        </Link>
      </div>
    );
  }
}

NewsGridItem.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  news_featured_image: PropTypes.any,
  slug: PropTypes.string,
  date: PropTypes.any,
};

export default NewsGridItem;
