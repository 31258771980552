import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { gsap } from 'gsap';

function HasNewsletter(properties) {
  const { wait, ready, artistData } = properties;
  const rlvReference = useRef();
  const [referenceVisible, setReferenceVisible] = useState(false);

  const newsletterContainer = useRef(null);
  const title = useRef(null);

  const revealReferences = useRef([]);

  const addToReferences = (element, index) => {
    revealReferences.current[index] = element;
  };

  useEffect(() => {
    if (!referenceVisible || !wait) {
      return;
    }
    const totalPanels = revealReferences.current.length - 1;
    let animateBackground;
    let animateNewsletterTimeline;
    if (totalPanels > 0) {
      animateBackground = gsap.timeline({
        scrollTrigger: {
          trigger: rlvReference.current,
          start: 'top bottom',
          end: 'top bottom',
          markers: false,
          scrub: 1,
        },
      });
      animateBackground.to(document.querySelector('body'), {
        backgroundColor: '#19698b',
        duration: 1,
      });

      for (const panel of revealReferences.current) {
        animateNewsletterTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: panel,
            start: 'top center',
            end: 'bottom bottom',
            markers: false,
            scrub: 1,
          },
        });
        animateNewsletterTimeline.fromTo(
          panel,
          { opacity: 0, y: '-10%' },
          { opacity: 1, y: '0%' }
        );
      }
    }
    ready(true);
    // detected rendering
    return () => {
      if (animateNewsletterTimeline) {
        animateNewsletterTimeline.scrollTrigger?.kill();
        animateNewsletterTimeline.pause(0).kill();
      }
      if (animateBackground) {
        animateBackground.scrollTrigger?.kill();
        animateBackground.pause(0).kill();
      }
    };
  }, [referenceVisible, wait, ready]);

  return (
    <section
      className="[ b-newsletter ][ c-section c-section--newsletter ][ js-section--newsletter ]"
      ref={(element) => {
        rlvReference.current = element;
        setReferenceVisible(!!element);
      }}
    >
      <div className="[ u-wrapper ]">
        <div className="[ b-newsletter__inner ]" ref={newsletterContainer}>
          <h2
            className="[ c-section__title ][ c-font__style--title ]"
            ref={title}
          >
            <Link
              to={{
                pathname: '/news',
              }}
            >
              Sign up
            </Link>
          </h2>
          <div className="[ b-newsletter__container ]">
            <div className="[ b-newsletter__background ]">
              <img
                src={artistData?.artist_image_square?.url}
                className="[ b-newsletter__image b-newsletter__image--mobile ]"
                width={artistData?.artist_image_square?.width}
                height={artistData?.artist_image_square?.height}
                alt="Newsletter Sign up"
                ref={(element) => addToReferences(element, 0)}
              />
              <img
                className="[ b-newsletter__image b-newsletter__image--desktop ]"
                src={artistData?.artist_image_wide?.url}
                alt="Newsletter Sign up"
                width={artistData?.artist_image_wide?.width}
                height={artistData?.artist_image_wide?.height}
                ref={(element) => addToReferences(element, 1)}
              />
            </div>

            <NavLink
              to="/newsletter"
              className="[ u-button ]"
              target="_blank"
              rel="noopener noreferrer"
              activeClassName="is-active"
            >
              Newsletter
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HasNewsletter;
